/* eslint-disable no-unused-vars */

import { Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import createDSPImg from '../../img/create_plan.png';
import updateImg from '../../img/edit_plan.png';
import createDSPWideImg from '../../img/create_plan_wide.png';
import updateWideImg from '../../img/edit_plan_wide.png';
import { OpenFile } from '../../utils/FileHelper';
import {globalStyles} from "../../GlobalStyles";
import CreateNewDSP from "./DSPPages/CreateNewDSP";


const useStyles = makeStyles((theme) => ({
  newDsplistIcon: {

    '&:hover': {
      cursor: "pointer"
    },
  },
  updateDsplistIcon: {

    '&:hover': {
      cursor: "pointer"

    },
  },
  listText: {
    fontWeight: 600,

    margin: '0 auto',
  },
  listContainer: {
    textAlign: 'center',
  },
}));

const Plan = (props:any): JSX.Element => {
  const classes = useStyles();
  const globalClasses=globalStyles();
  const history = useHistory();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [dsp,setdsp]=useState('choose');
  
  const onClickUpload = () => {
    inputFileRef.current!.click();
  };

  const handleUploadFile = ({ target }: any) => {
    const fileReader = new FileReader();

    fileReader.readAsText(target.files[0]);
    fileReader.onload = (e: any) => {
      const data = OpenFile(e.target.result);
      if(data==null)
        alert('File cannot be loaded, only the author of the DSP can open it.');
      else{
        const parsedData = JSON.parse(data);
        console.log(parsedData);
        Object.keys(parsedData).forEach((key) => {
          sessionStorage.setItem(key, parsedData[key]);
        });
        //history.push('/DSP/CreateNewDSP', ['keep']);
        setdsp('keep');
      }
    };
  }

  return (
    <>
    
      {dsp==='dsp'&&<CreateNewDSP isKeep={false}></CreateNewDSP>}
      {dsp==='keep'&&<CreateNewDSP isKeep={true}></CreateNewDSP>}
      {dsp==='choose'&&<>  <Grid item xs={12}>
        <Typography
          variant='h4'
          className={globalClasses.titleText}
        >
          Create/Edit Plan
        </Typography>
        <p style={{ marginLeft: '5%'}}
        >
        To learn more about supporting Indigenous families, key Indigenous concepts of child development, the current and historical challenges faced by Indigenous people, and the guiding principles used in creating this tool/program, <a href=" https://sickkids.b-cdn.net/medialibrary/00000000-0000-0000-0000-000000000000/0dcc390e-234b-44d8-9b21-440b24ae405e/2/0dcc390e-234b-44d8-9b21-440b24ae405e.pdf"><b> please explore the NTS Handbook here</b></a>.
  
        </p>
            </Grid><Grid container style={{ marginTop: '-15px' }}>


      <Hidden smDown>
      <Grid item xs={12} md={6} className={classes.listContainer} onClick={() => setdsp('dsp')}>
        <img src={createDSPImg} alt='Create/Edit Plan' className={classes.newDsplistIcon} />
 
      </Grid>
      <Grid item xs={12} md={6} className={classes.listContainer} onClick={onClickUpload}>
      <img src={updateImg} alt='Update DSP' className={classes.updateDsplistIcon} />
      </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid item xs={12}  alignItems='center' justifyContent="center" style={{marginTop:'60px'}} >
      <Grid item xs={12} md={6} className={classes.listContainer} onClick={() => setdsp('dsp')}>
      <img src={createDSPWideImg} alt='Create/Edit Plan' className={classes.newDsplistIcon} style={{ width: '70%' }} />
 
      </Grid>
      <Grid item xs={12} md={6} className={classes.listContainer} onClick={onClickUpload}>
      <img src={updateWideImg} alt='Update DSP' className={classes.updateDsplistIcon} style={{ width: '70%' }} />
  </Grid>
  </Grid>
        </Hidden>

<input id="fileInput" type="file" ref={inputFileRef} style={{ display: 'none' }} onChange={handleUploadFile} />
</Grid></>}
      
    </>
  )

}

export default Plan;